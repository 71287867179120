<template>


  <el-container>

    <el-main :style="defaultHeight">

      <el-row>
        <el-button type="danger"  @click="clickTo" round>传送(请用苹果Safari浏览器访问网站)</el-button>
      </el-row>
      <el-row>

      </el-row>

    </el-main>
  </el-container>

</template>

<script>

export default {

  data() {
    return {
      defaultHeight: {
        height: "",
        src: "/aid",
        emHeight:0
      }
    };
  },
  methods: {
    //定义方法，获取高度减去头尾
    getHeight() {
      /*this.defaultHeight.height = window.innerHeight + "px";*/
    },
    clickTo() {
      window.open("/aid", "_blank"); //跳转新页面
    }
  },
  created() {
   /* window.open("/aid", "_blank");*/
    //页面创建时执行一次getHeight进行赋值，顺道绑定resize事件
 /*   window.addEventListener("resize", this.getHeight);
    this.getHeight();*/

  }
};
</script>

<style scoped>
.el-main {
  background-color: #fff1f1;
  color: #333;
  text-align: center;
  line-height: 500px;
  height: calc(100vh);
}
</style>
