<template>
  <el-row :gutter="20">
    <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>

    <el-col :span="16">
      <div class="grid-content bg-purple">
        <el-tabs v-model="activeName" @tab-click="handleClick1">
          <el-tab-pane label="苹果账号购买" name="first1">
            <el-row>
              <el-col :span="24">
                <div class="grid-content bg-purple-dark">

<!--                  <el-container>
                    <el-header>Header</el-header>
                    <el-main>Main</el-main>
                  </el-container>-->
                  <el-container>
                    <el-main>
                      <br/>

                      1.从商店去登录.如登录设置,请务必关闭查找我的Iphone(ID锁)<br/>
                      2.未收到账号联系客服或点此查询：查询账号<br/>
                      3.10个以上6折/100个以上5折(自动折扣)<br/>
                      4.售后：7天内账号停用未激活包换<br/>
                      QQ客服：5363036点击这里给我发消息<br/>
                      <br/>
                    </el-main>
                  </el-container>
                </div>
              </el-col>

              <el-col :span="24">
                苹果appleid账号(icloud.com后缀）（带密保可改资料）
                <el-container>
                  <el-main>

                    <div  id="hideme1">

                      <el-table
                          :data="tableData1"
                          style="width: 100%"
                      >
                        <el-table-column
                            prop="icon"
                            width="50"
                            label="操作">
                          <template slot-scope="scope">

                            <el-button type="text" @click="editHandle(scope.row.accountArea)">购买</el-button>

                          </template>
                        </el-table-column>
                        <el-table-column
                            prop="spare2"
                            label="商品名称"
                            width="90">

                        </el-table-column>

                        <el-table-column

                            label="发货模式"
                            width="85">

                            <el-tag size="small"  type="success">
                              自动发货
                            </el-tag>

                        </el-table-column>

                        <el-table-column
                            prop="spare1"
                            label="库存"
                            width="90">
                        </el-table-column>

                        <el-table-column
                            prop="price"
                            label="单价"
                            width="90">
                          <template slot-scope="price">
                            ¥10.0
                          </template>
                        </el-table-column>

                      </el-table>
                    </div>

                    <div  id="hideme2" style="display: none" >


                      <el-row>
                        <el-col :span="24">
                          <div class="grid-content bg-purple-dark">
                            自动发货 美国id  库存:2141
                          </div>

                        </el-col>
                      </el-row>

                      <el-row>
                        <el-col :span="24">
                          <div class="grid-content bg-purple-dark"  >
                            价格：
                            <span class="trade-price" id="divId" style="color: #ff0000;font-size:23px">¥ 10.00</span>  单价: ¥ 10.00</span>
                          </div>

                        </el-col>
                      </el-row>


                      <el-form :model="ruleForm" status-icon  :rules="rules"  ref="ruleForm" label-width="100px" class="demo-ruleForm">
                        <el-form-item label="购买数量" prop="saleTotal">

                          <el-input-number size="medium"  v-model="ruleForm.saleTotal" @change="handleChange" :min="1" :max="100"  ></el-input-number>
                        </el-form-item>
                        <el-form-item label="QQ/电话号码" prop="qqPhone">
                          <el-input  v-model="ruleForm.qqPhone" placeholder="用于查询支付订单时需要。"></el-input>
                        </el-form-item>
<!--                        <el-form-item label="查询密码" prop="queryPassword">
                          <el-input v-model.number="ruleForm.queryPassword" placeholder="自行填写（用于查询支付订单时需要）"></el-input>
                        </el-form-item>-->
                        <el-form-item>
                          <el-button type="primary" @click="submitForm('ruleForm')">立即购买</el-button>
                          <el-button @click="resetForm('ruleForm')">重置</el-button>
                        </el-form-item>
                      </el-form>


                    </div>



                  </el-main>
                </el-container>
              </el-col>

            </el-row>
          </el-tab-pane>
          <el-tab-pane label="查询支付订单" name="second" >
            查询订单

            <el-tabs v-model="activeNameT" @tab-click="handleClick">
              <el-tab-pane label="用户管理" name="first">
                用户管理
              </el-tab-pane>
              <el-tab-pane label="订单号查询" name="second">

                <el-form :model="ruleForm2" status-icon  :rules="rules2" ref="ruleForm2" label-width="100px" class="demo-ruleForm">


                  <el-form-item label="订单号" prop="orderNumber">
                    <el-input  v-model="ruleForm2.orderNumber" placeholder="请输入订单号 或者 QQ号 或者 手机号码" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item label="验证码" prop="code">
                    <el-input v-model.number="ruleForm2.code" placeholder="请输入验证码" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item label="订单账号" style="display: none" id="orderid1" >
                    <el-input type="textarea" id="input" v-model="ruleForm2.orderInformation" :autosize="{ minRows: 10, maxRows: 10}"></el-input>

                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="submitForm2('ruleForm2')">查询</el-button>
                    <el-button @click="emptyForm2('ruleForm2')">重置</el-button>
                    <el-button @click="resetForm2('ruleForm2')"   >一键复制订单账号</el-button>
                    <p ></p>
                  </el-form-item>


                </el-form>


              </el-tab-pane>
<!--              <el-tab-pane label="角色管理" name="third">
                角色管理
              </el-tab-pane>-->

            </el-tabs>

          </el-tab-pane>

          <el-tab-pane label="免费苹果账号" name="third">
            <div style="color:#37B328">网站App验证账户,禁止登陆iCloud！</div>
            <el-tabs v-model="activeName3" @tab-click="handleClick">
              <el-tab-pane label="使用后请退出账号" name="first">
                <div class="grid-content bg-purple-dark">
                  <el-container>
                    <el-main>
                      <div style="color:#FF0000">备注：不论你是新手还是老手，必须看登陆教程，使用后请退出账号；禁止登陆iCloud，否则一切后果自负！</div> <br/>
                      <div style="color:#FF0000">警告：使用本站共享账号，必看登录教程；登录过程出现【AppleID安全】提示页面，必须点下方蓝色【其他选项】，再点【不升级】跳过绑定手机，否则导致手机被锁、被短信和电话轰炸活该！</div><br/>
                    </el-main>
                  </el-container>
                </div>
                <el-row>
                  <el-col :span="11">
                    <div class="grid-content bg-purple">
                      <img alt="Vue logo" src="../assets/login.png" css="dispaly:block;margin:0 auto;" >
                    </div>
                  </el-col>
                  <el-col :span="13">
                    <el-row>
                      <el-tabs v-model="activeName5" @tab-click="handleClick">
                        <el-tab-pane label="美国账号(AppleID)" name="first">
                          账号：i-urigbje@aneeo.cc <br>
                          密码：查看左边教程图,最后两张 回答问题获取 (请耐心看完教程)

                          <div><br>
                            <el-radio v-model="radio1" label="1" border>继续</el-radio>
                            <el-radio v-model="radio1" label="2" border>其他选项</el-radio>
                          </div>
                          <div style="margin-top: 20px">
                            <el-radio v-model="radio2" label="1" border size="medium">升级账号安全</el-radio>
                            <el-radio v-model="radio2" label="2" border size="medium">不升级</el-radio>
                          </div>
                          <br><el-input v-model="input" placeholder="密码:查看最后两张图片 选中答案获得密码"></el-input>

                        </el-tab-pane>
                        <el-tab-pane label="日本账号(AppleID)" name="second">配置管理</el-tab-pane>
                        <el-tab-pane label="法国账号(AppleID)" name="third">角色管理</el-tab-pane>

                      </el-tabs>
                    </el-row>

                  </el-col>
                </el-row>

              </el-tab-pane>

            </el-tabs>
          </el-tab-pane>


          <el-tab-pane label="关于我们" name="fourth">
            关于我们<br/>
            <div class="grid-content bg-purple-dark">
              <el-container>
                <el-main>
                  <br/>
                  1.账号请从商店登录。如登录设置，请务必关闭查找我的Iphone(ID锁)<br/>

                  2.未收到账号点此查询：查询账号<br/>

                  3.QQ客服：5363036点击这里给我发消息<br/>

                  4.折扣：10个以上6折/100个以上5折<br/>

                  5.售后：7天内账号停用未激活包换<br/>
                  <br/>
                </el-main>
              </el-container>
            </div>
          </el-tab-pane>

        </el-tabs>
      </div>
    </el-col>

    <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
  </el-row>
</template>

<script>

export default {
  data() {
    var orderNumber = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入订单号 或者 QQ号 或者 手机号码'));
      } else {

        callback();
      }
    };

    var code = (rule, value, callback) => {

     if (value === '') {
        callback(new Error('请输入验证码'));
      } else {

       callback();
     }
    };

    var qqPhone = (rule, value, callback) => {

      if (value === '') {
        callback(new Error('请输入QQ或电话号码,用于查询支付订单时需要。'));
      } else {
        if(value.length<5){
          callback(new Error('输入的QQ或电话号码,不能少于5位数。'));
        }else{
          callback();
        }

      }
    };


    return {
      activeName: 'first1',
      activeNameT: 'second',
      activeName3: 'first',
      activeName5: 'first',
      input1: '',
      input2: '',
      input3: '',
      input4: '',

      input: '',
      radio1: '1',
      radio2: '1',
      num2: 1,
      orderNumber:'',
      spare1:''  ,
      tableData1: [],
      ruleForm: {
        saleTotal:1,
        qqPhone:'',

        accountArea:''

      },
      rules: {
        qqPhone: [
          { validator: qqPhone, trigger: 'blur' }
        ]
      },
      ruleForm2: {

        code:'',
        orderNumber:'',
        accountArea:'',
        orderInformation: '',

        spare1:''

      },
      rules2: {
        orderNumber: [
          { validator: orderNumber, trigger: 'blur' }
        ],
        code: [
          { validator: code, trigger: 'blur' }
        ]

      }
    };
  },
  created() {
    this.getUserList()
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },handleClick1(tab, event) {
      console.log(tab, event);

      if(tab.name=='first1'){

        document.querySelector('#hideme1').style.display = 'block';
        document.querySelector('#hideme2').style.display = 'none';
      }else if(tab.name=='second' ){
        // document.querySelector('#orderid2').style.display = 'none';
        /* document.querySelector('#hideme2').style.display = 'none';*/
      }
    },
    editHandle(id) {

       /* alert(id);*/
      document.getElementById( "divId" ).innerHTML='¥ 10.00 ';
      this.ruleForm.qqPhone='';
      this.ruleForm.saleTotal=1;
      this.accountArea=id;
         document.querySelector('#hideme1').style.display = 'none';
         document.querySelector('#hideme2').style.display = 'block';
    },
    editHandle2(id) {

      alert(this.accountArea);

    },

    getUserList() {
      this.$axios.get("/miss", {
        params: {

        }
      }).then(res => {

        this.tableData1 = res.data.data

      })
    },submitForm(formName) {


      this.ruleForm.accountArea=this.accountArea;
     // alert('qqPhone!'+this.ruleForm.qqPhone +" queryPassword "+this.ruleForm.queryPassword+" saleTotal "+this.ruleForm.saleTotal+" accountArea "+this.ruleForm.accountArea);

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.post('/sale/order/save', this.ruleForm)
              .then(res => {

                this.$message({
                  showClose: true,
                  message: '恭喜你，操作成功',
                  type: 'success',
                  onClose:() => {
                    this.orderNumber=res.data.data.orderNumber;
                    /*
                    alert(this.orderNumber);*/
                    /*alert('submit!');*/
                    //this.getUserList()
                  }
                });

                this.dialogVisible = false
              })

         // alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      document.getElementById( "divId" ).innerHTML='¥ 10.00 ';
      this.$refs[formName].resetFields();
    },submitForm2(ruleForm2) {
      this.$refs[ruleForm2].validate((valid) => {
        if (valid) {

          this.$axios.get('/sale/order/orderQuery/' + this.ruleForm2.orderNumber).then(res => {

            var num =JSON.stringify(res.data.data).length;
            if (num==2) {
              this.$message.error('订单不存在,请检查订单或联系管理员。');
            }else{
              document.querySelector('#orderid1').style.display = 'block';

              //alert(JSON.stringify(res.data.data));
              this.ruleForm2.orderInformation=res.data.data.spare1;
              this.$message({
                message: '一共查询到('+res.data.data.spare2+')条订单数据。',
                type: 'success'
              });
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    }, resetForm2(formName) {
      if(this.ruleForm2.orderInformation.length>=1){
        var input = document.getElementById( "input" );
        input.select();
        document.execCommand("copy");
        this.$message({
          message: '复制成功',
          type: 'success'
        });
      }else{

          this.$message({
            showClose: true,
            message: '需要先查询订单数据。'
          });
      }

    },emptyForm2(formName2) {

      this.ruleForm2.orderInformation='';
      document.querySelector('#orderid1').style.display = 'none';
      this.$refs[formName2].resetFields();
    },handleChange(value) {
      /* 购买界面 购买数量 价格设置 */
      var moni = 10*value;
      document.getElementById( "divId" ).innerHTML='¥ '+moni +'.00 ';
      console.log(value);
    }


  }
};


</script>

<style scoped>
.el-row {
  margin-bottom: 20px;
&:last-child {
   margin-bottom: 0;
 }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: rgba(255, 255, 255, 0.38);
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}





</style>