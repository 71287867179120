import axios from "axios";
import router from "./router";
import Element from "element-ui"

import { rsaEncode, aesEncode, aesDecode, getAesKeyByApp } from "@/assets/js/aesRsaUtils.js";

///axios.defaults.baseURL = "http://localhost:8081"
axios.defaults.baseURL = "http://localhost:8080"//正式
//axios.defaults.baseURL = "https://test.fompay.cc" //测试

const request = axios.create({
	timeout: 5000,
	headers: {
		'Content-Type': "application/json; charset=utf-8"
	}
})

request.interceptors.request.use(config => {
	config.headers['Authorization'] = localStorage.getItem("token")
	//console.log(config)
	return config
})

request.interceptors.response.use(
	response => {

		//console.log("response 3->" + JSON.stringify(response) )

		let res = response.data

		if (res.code === 200) {
			return response
		} else {

			Element.Message.error(!res.msg ? '系统异常' : res.msg)
			if(res.msg == "请检查用户名,密码,验证码是否正确。"){
				alert("请检查用户名,密码,验证码是否正确。")
				location.reload()
			}

			return Promise.reject(response.data.msg)

		}
	},
	error => {
		let miss =0;
		console.log("666666666666---+++ "+error)

		if (error.response.data) {
			error.massage = error.response.data.msg
		}

		if (error.response.status === 401) {

			miss=2;
			Element.Message.error('登录超时，请您重新登录。', {duration: 6000})
			/*alert(1)*/
			localStorage.clear()
			sessionStorage.clear()
			router.push("/login")
			//router.push("/login")
		}
		if (error.response.status === 500) {

			miss=2;
			Element.Message.error('登录超时，请您重新登录。', {duration: 6000})
			/*alert(1)*/
			localStorage.clear()
			sessionStorage.clear()
			router.push("/login")
			//router.push("/login")
		}
		/*6666666666666666666666666*/
		if(miss===0){
			Element.Message.error(error.massage, {duration: 3000})
		}

		return Promise.reject(error)
	}
)

export default request