<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-input
            v-model="searchForm.usEmail"
            placeholder="卡所属用户邮箱"
            clearable
        >
        </el-input>

      </el-form-item>

      <el-form-item>
        <el-input
            v-model="searchForm.cardNo"
            placeholder="卡号"
            clearable
        >
        </el-input>

      </el-form-item>


      <el-form-item>
        <el-input
            v-model="searchForm.cardId"
            placeholder="商户卡ID"
            clearable >
        </el-input>

      </el-form-item>

      <el-form-item>
      <el-select v-model="searchForm.countries"   placeholder="所属国家" >
        <el-option
            v-for="item in optionsT"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      </el-form-item>

      <el-form-item>

        <el-date-picker
            v-model="value2"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
            :default-time="['00:00:00','23:59:59']"
            @change="dateFormat"
        >
        </el-date-picker>

      </el-form-item>


      <!--      <el-form-item>
              <el-select v-model="searchForm.type" placeholder="请选择开卡状态">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>-->

      <el-form-item>
        <el-button @click="getUserList">搜索</el-button>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="dialogVisible = true">手动添加卡片</el-button>

      </el-form-item>

      <el-popconfirm title="确定新增美国卡片吗？一次生成15张" @confirm="sa('1')">
        <el-button type="primary" slot="reference">自加美卡</el-button>

      </el-popconfirm>
      &nbsp;
      <el-popconfirm title="确定新增香港卡片吗？一次生成15张" @confirm="sa('2')">

        <el-button type="primary" slot="reference">自加港卡</el-button>
      </el-popconfirm>
      <!--      <el-form-item>
              <el-popconfirm title="这是确定批量删除吗？" @confirm="delHandle(null)">
                <el-button type="danger" slot="reference" :disabled="delBtlStatu" v-if="hasAuth('card:card:delete')">批量删除</el-button>
              </el-popconfirm>
            </el-form-item>-->
    </el-form>

    <!--        @cell-dblclick="rowClick"-->
    <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @cell-dblclick="rowClick"
        border
        stripe
        @selection-change="handleSelectionChange">

      <el-table-column
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column
          prop="id"
          label="id"
          width="70">
      </el-table-column>

      <el-table-column
          prop="cardUserId"
          label="备用卡片"
          width="80">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.cardUserId === ''" type="danger">未分配</el-tag>
          <el-tag size="small" v-else-if=" '1' === '1' " type="success">已分配</el-tag>
        </template>
      </el-table-column>



      <el-table-column
          prop="countries"
          label="国家"
          width="65">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.countries === 'USA'" type="primary">美国</el-tag>
          <el-tag size="small" v-else-if="scope.row.countries === 'HK'" type="warning">香港</el-tag>
        </template>
      </el-table-column>




      <!---->
      <el-table-column
          prop="usEmail"
          label="卡所属用户邮箱"
          width="270">
      </el-table-column>

      <el-table-column
          prop="amount"
          label="卡片余额"
          width="100">
      </el-table-column>

      <el-table-column
          prop="isAmount"
          label="待充值余额"
          width="100">
      </el-table-column>
      <el-table-column
          prop="isAmountt"
          label="待提现余额"
          width="100">
      </el-table-column>

      <!---->
      <el-table-column
          prop="theSurname"
          label="姓"
          width="100">
      </el-table-column>

      <el-table-column
          prop="theName"
          label="名"
          width="100">
      </el-table-column>

      <!---->
      <el-table-column
          prop="cardNo"
          label="卡号"
          width="160">
      </el-table-column>
      <el-table-column
          prop="cardId"
          label="商户卡ID"
          width="160">
      </el-table-column>


      <el-table-column
          prop="exprDay"
          label="有效日期"
          width="80">
      </el-table-column>

      <el-table-column
          prop="cardType"
          label="卡片种类"
          width="90">
      </el-table-column>

      <el-table-column
          prop="co"
          label="供应商"
          width="65">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.co === '0'" type="plain">环球</el-tag>
          <el-tag size="small" v-else-if="scope.row.co === '1'" type="plain">PP</el-tag>

        </template>
      </el-table-column>
      <el-table-column
          prop="isStatus"
          label="是否停用"
          width="80">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.isStatus === '0'" type="success">启用</el-tag>
          <el-tag size="small" v-else-if="scope.row.isStatus === '1'" type="danger">已冻结</el-tag>
        </template>
      </el-table-column>

      <el-table-column
          prop="theActivation"
          label="是否激活"
          width="80">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.theActivation === '0'" type="danger">未激活</el-tag>
          <el-tag size="small" v-else-if="scope.row.theActivation === '1'" type="success">激活</el-tag>
        </template>
      </el-table-column>


      <el-table-column
          prop="type"
          label="开卡状态"
          width="90">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.type === 1" type="success">开卡中</el-tag>
          <el-tag size="small" v-else-if="scope.row.type === 2" type="danger">开卡失败</el-tag>
          <el-tag size="small" v-else-if="scope.row.type === 3" type="success">开卡成功</el-tag>
        </template>
      </el-table-column>

      <el-table-column
          prop="grade"
          label="卡片等级"
          width="100">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.grade === '01'" type="success">标准卡</el-tag>
          <el-tag size="small" v-else-if="scope.row.grade === '02'" type="success">高级卡</el-tag>
          <el-tag size="small" v-else-if="scope.row.grade === '03'" type="success">白金卡</el-tag>
          <el-tag size="small" v-else-if="scope.row.grade === '04'" type="success">黑金卡</el-tag>
          <el-tag size="small" v-else-if="scope.row.grade === '05'" type="success">免KYC卡片</el-tag>
        </template>
      </el-table-column>


<!--      <el-table-column
          prop="creationTime"
          label="开卡日期"
          width="165">
      </el-table-column>-->

      <el-table-column
          prop="creationTime"
          label="开卡日期"
          width="165">
        <template #default="{row}">
          {{ row.creationTime.year }}-{{ row.creationTime.nano }}{{ row.creationTime.monthValue }}-{{ row.creationTime.dayOfMonth }} {{ row.creationTime.hour }}:{{ row.creationTime.minute }}:{{ row.creationTime.second }}
        </template>
      </el-table-column>

      <!--      <el-table-column
                prop="industry"
                label="所属行业"
                width="180">
            </el-table-column>-->


      <el-table-column
          prop="icon"
          width="150px"
          label="操作">

        <template slot-scope="scope">

          <el-button type="text" @click="editHandle(scope.row.id)">修改</el-button>

        </template>
      </el-table-column>


    </el-table>

    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[15, 20, 50, 100]"
        :current-page="current"
        :page-size="size"
        :total="total">
    </el-pagination>


    <!--新增对话框-->
    <el-dialog
        title="卡片新增或更新 (注: 【商户卡ID】和【卡号】非常重要如果 如不匹配会导致卡余额错误)  "
        :visible.sync="dialogVisible"
        width="800px"
        :before-close="handleClose"
    >

      <el-form :model="editForm" :rules="editFormRules" ref="editForm">
        <!--        <el-form-item label="先查看今天数据是否入库" prop="accountNumber" >

                </el-form-item> :disabled="true"-->

        <el-row>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <el-form-item label="属用户邮箱" prop="usEmail" label-width="100px">
                <el-input v-model="editForm.usEmail" autocomplete="off"></el-input>
              </el-form-item>

              <el-form-item label="姓" prop="theSurname" label-width="100px">
                <el-input v-model="editForm.theSurname" autocomplete="off"></el-input>
              </el-form-item>

              <el-form-item label="名" prop="theName" label-width="100px">
                <el-input v-model="editForm.theName" autocomplete="off"></el-input>
              </el-form-item>

              <el-form-item label="州" prop="state" label-width="100px">
                <!--              <el-input v-model="editForm.state" autocomplete="off"></el-input>-->

                <el-select v-model="editForm.state" placeholder="请选择开卡状态">
                  <el-option
                      v-for="items in options"
                      :key="items.value"
                      :label="items.label"
                      :value="items.value"
                  >

                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="邮编" prop="zipCode" label-width="100px">
                <el-input v-model="editForm.zipCode" autocomplete="off"></el-input>
              </el-form-item>


              <el-form-item label="有效日期" prop="exprDay" label-width="100px">
                <el-input v-model="editForm.exprDay" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="商户卡ID" prop="cardId" label-width="100px">
                <el-input v-model="editForm.cardId" autocomplete="off"></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">

              <el-form-item label="卡号" prop="cardNo" label-width="100px">
                <el-input v-model="editForm.cardNo" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="cvv" prop="cvv" label-width="100px">
                <el-input v-model="editForm.cvv" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="国家" prop="countries" label-width="100px">
                <el-select v-model="editForm.countries"   placeholder="所属国家" >
                  <el-option
                      v-for="item in optionsJ"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <!--
                          <el-form-item label="年"  prop="years" label-width="100px">
                            <el-input v-model="editForm.years" autocomplete="off"></el-input>
                          </el-form-item>

                          <el-form-item label="月"  prop="month" label-width="100px">
                            <el-input v-model="editForm.month" autocomplete="off"></el-input>
                          </el-form-item>-->


              <el-form-item label="城市" prop="city" label-width="100px">
                <el-input v-model="editForm.city" autocomplete="off"></el-input>
              </el-form-item>


              <el-form-item label="街道" prop="street" label-width="100px">
                <el-input v-model="editForm.street" autocomplete="off"></el-input>
              </el-form-item>

              <el-form-item label="卡片余额" prop="amount" label-width="100px">
                <el-input v-model="editForm.amount" autocomplete="off"></el-input>
              </el-form-item>


            </div>
          </el-col>
        </el-row>

        <el-form-item label="卡片种类" prop="cardType" label-width="100px">
          <el-radio-group v-model="editForm.cardType">
            <el-radio :label="'visa'">visa</el-radio>
            <el-radio :label="'MasterCard'">八达通</el-radio>
          </el-radio-group>
        </el-form-item>
        <!--        <el-form-item label=" 1visa 2八达通"  prop="cardType" label-width="100px">
                  <el-input v-model="editForm.cardType" autocomplete="off"></el-input>
                </el-form-item>-->


        <el-form-item label="是否激活" prop="theActivation" label-width="100px">
          <el-radio-group v-model="editForm.theActivation">
            <el-radio :label="'0'">未激活</el-radio>
            <el-radio :label="'1'">激活</el-radio>
          </el-radio-group>
        </el-form-item>


        <el-form-item label="开卡状态" prop="type" label-width="100px">
          <el-radio-group v-model="editForm.type">
            <el-radio :label="1">开卡中</el-radio>
            <el-radio :label="2">开卡失败</el-radio>
            <el-radio :label="3">开卡成功</el-radio>
          </el-radio-group>
        </el-form-item>
        <!--
                <el-form-item label=" 开卡状态 1开卡中 2开卡失败 3开卡成功"  prop="type" label-width="100px">
                  <el-input v-model="editForm.type" autocomplete="off"></el-input>
                </el-form-item>-->

        <el-form-item label="卡片等级" prop="grade" label-width="100px">
          <el-radio-group v-model="editForm.grade">
            <el-radio :label="'01'">标准卡</el-radio>
            <el-radio :label="'02'">高级卡</el-radio>
            <el-radio :label="'03'">白金卡</el-radio>
            <el-radio :label="'04'">黑金卡</el-radio>
            <el-radio :label="'05'">免KYC</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="状态" prop="isStatus" label-width="100px">
          <el-radio-group v-model="editForm.isStatus">
            <el-radio :label="'0'">可用</el-radio>
            <el-radio :label="'1'">冻结 【姓名,地址,状态】(如有变动会自动同步商户卡片参数)</el-radio>
          </el-radio-group>
        </el-form-item>


      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm('editForm')">取 消</el-button>
        <el-button type="primary" @click="submitForm('editForm')">确 定</el-button>
      </div>
    </el-dialog>


    <!-- 分配权限对话框 -->
    <el-dialog title="分配角色" :visible.sync="roleDialogFormVisible" width="600px">

      <el-form :model="roleForm">
        <el-tree
            :data="roleTreeData"
            show-checkbox
            ref="roleTree"
            :check-strictly=checkStrictly
            node-key="id"
            :default-expand-all=true
            :props="defaultProps">
        </el-tree>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="roleDialogFormVisible=false">取 消</el-button>
        <el-button type="primary" @click="submitRoleHandle('roleForm')">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import qs from 'qs'
import { rsaEncode, aesEncode, aesDecode, getAesKeyByApp } from "@/assets/js/aesRsaUtils.js";
import Element from "element-ui";
const publicKey =
    "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAi2PYopFxE/QgyBHjxjkhcalBHyg4UAFBYdV09LyCOCA/Xb/knor5LlPcSl1ykuUl041yDoKPU52oTcRJA2gZ4SWfXWvatVE4OHf3ULL87+87XWqjNblLowk4TkFbimW/yNs/gSAqnlIaiNcZ0TtiSrf8zRsTvdijSby0hJNqgjblUrsVEZ02lhFoJ+6P6sYUVr8VHXxcfTaH8fyEukU6N56LfIsNgfolAiG1wtFZmMqTd+a12jfho3SjJDfiZxCQ6W88lNUgObb3/W/y70rId2UgUXzB4LhspgbjsjJuYY/skfiYspb9N18viSwdho007w1K4xSz0Tpeatvo2woyYQIDAQAB";

export default {
  name: "Role",
  data() {
    return {
      options: [{value: 'AL', label: '阿拉巴马州 Alabama'}
        , {value: 'AK', label: '阿拉斯加州 Alaska'}
        , {value: 'AZ', label: '亚利桑那州 Arizona'}
        , {value: 'AR', label: '阿肯色州 Arkansas'}
        , {value: 'CA', label: '加利福尼亚州 California'}
        , {value: 'CO', label: '科罗拉多州 Colorado'}
        , {value: 'CT', label: '康涅狄格州 Connecticut'}
        , {value: 'DE', label: '特拉华州 Delaware'}
        , {value: 'FL', label: '佛罗里达州 Florida'}
        , {value: 'GA', label: '乔治亚州 (佐治亚州 )Georgia'}
        , {value: 'HI', label: '夏威夷州 Hawaii'}
        , {value: 'ID', label: '爱达荷州 Idaho'}
        , {value: 'IL', label: '伊利诺斯州 Illinois'}
        , {value: 'IN', label: '印第安纳州 Indiana'}
        , {value: 'IA', label: '爱荷华州 Iowa'}
        , {value: 'KS', label: '堪萨斯州 Kansas'}
        , {value: 'KY', label: '肯塔基州 Kentucky'}
        , {value: 'LA', label: '路易斯安那州 Louisiana'}
        , {value: 'ME', label: '缅因州 Maine'}
        , {value: 'MD', label: '马里兰州 Maryland'}
        , {value: 'MA', label: '马萨诸塞州 (麻省、麻州 )Massachusetts'}
        , {value: 'MI', label: '密歇根州 Michigan'}
        , {value: 'MN', label: '明尼苏达州 Minnesota'}
        , {value: 'MS', label: '密西西比州 Mississippi'}
        , {value: 'MO', label: '密苏里州 Missouri'}
        , {value: 'MT', label: '蒙大拿州 Montana'}
        , {value: 'NE', label: '内布拉斯加州 Nebraska'}
        , {value: 'NV', label: '内华达州 Nevada'}
        , {value: 'NH', label: '新罕布什尔州 New Hampshire'}
        , {value: 'NJ', label: '新泽西州 New Jersey'}
        , {value: 'NM', label: '新墨西哥州 New Mexico'}
        , {value: 'NY', label: '纽约州 New York'}
        , {value: 'NC', label: '北卡罗来纳州 North Carolina'}
        , {value: 'ND', label: '北达科他州 North Dakota'}
        , {value: 'OH', label: '俄亥俄州 Ohio'}
        , {value: 'OK', label: '俄克拉荷马州 Oklahoma'}
        , {value: 'OR', label: '俄勒冈州 Oregon'}
        , {value: 'PA', label: '宾夕法尼亚州 Pennsylvania'}
        , {value: 'RI', label: '罗德岛州 Rhode Island'}
        , {value: 'SC', label: '南卡罗来纳州 South Carolina'}
        , {value: 'SD', label: '南达科他州 South Dakota'}
        , {value: 'TN', label: '田纳西州 Tennessee'}
        , {value: 'TX', label: '得克萨斯州 Texas'}
        , {value: 'UT', label: '犹他州 Utah'}
        , {value: 'VT', label: '佛蒙特州 Vermont'}
        , {value: 'VA', label: '弗吉尼亚州 Virginia'}
        , {value: 'WA', label: '华盛顿州 Washington'}
        , {value: 'WV', label: '西弗吉尼亚州 West Virginia'}
        , {value: 'WI', label: '威斯康辛州 (威斯康星州 )Wisconsin'}
        , {value: 'WY', label: '怀俄明州 Wyoming'}],
      optionsT: [{
        value: '',
        label: ''
      },{value: 'USA', label: '美国'}
        , {value: 'HK', label: '香港'}
        ,{value: '666666', label: '美国未分配卡片'}
        , {value: '777777', label: '香港未分配卡片'}],
      optionsJ: [{value: 'USA', label: '美国'}
        , {value: 'HK', label: '香港'}
       ],

      sign: {
        signWSB:'',
        signWSBB:''
      },
      searchForm: {},
      delBtlStatu: true,

      total: 0,
      size: 15,
      current: 1,

      dialogVisible: false,
      editForm: {},
      encryption: { key:'',
        data:''},
      pickerOptions: {

        shortcuts: [{
          text: '最近一天',
          onClick(picker) {
            const end = new Date(new Date(new Date().getTime()).setHours(23, 59, 59, 999));
            const start = new Date(new Date(new Date().getTime()).setHours(0, 0, 0, 0));
            picker.$emit('pick', [start, end]);

          }
        }, {
          text: '昨天',
          onClick(picker) {
            const start = new Date(new Date(new Date().getTime() - 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0));
            const end = new Date(new Date(new Date().getTime() - 24 * 60 * 60 * 1000).setHours(23, 59, 59, 999));
            picker.$emit('pick', [start, end]);
          }
        }
          , {
            text: '最近一周',
            onClick(picker) {
              const end = new Date(new Date(new Date().getTime()).setHours(23, 59, 59, 999));//new Date();
              const start = new Date();

              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date(new Date(new Date().getTime()).setHours(23, 59, 59, 999));//new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date(new Date(new Date().getTime()).setHours(23, 59, 59, 999));//new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);

            }
          }]
      },
      value2: '',
      tableData: [],

      editFormRules: {
        usEmail: [
          {required: true, message: '请输入所属用户邮箱', trigger: 'blur'}
        ],
        theSurname: [
          {required: true, message: '请输入姓', trigger: 'blur'}
        ],
        theName: [
          {required: true, message: '请输入名', trigger: 'blur'}
        ],
        cardNo: [
          {required: true, message: '请输入卡号', trigger: 'blur'}
        ],
        cvv: [
          {required: true, message: '请输入cvv', trigger: 'blur'}
        ],
        cardId: [
          {required: true, message: '请输入 商户卡ID', trigger: 'blur'}
        ]
      },

      multipleSelection: [],

      roleDialogFormVisible: false,
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      roleForm: {},
      roleTreeData: [],
      treeCheckedKeys: [],
      checkStrictly: true

    }
  },
  created() {


    this.getUserList()


    this.$axios.get("/sys/roleMGQZHP/list").then(res => {
      this.roleTreeData = res.data.data.records
    })
  },
  methods: {

    dateFormat(picker) {
      /* 法一 */
      /* this.value2 = [
         new Date(new Date().setHours(0, 0, 0, 0)),
         new Date(new Date().setHours(23, 59, 59, 999))
       ];*/
      console.log("11" + new Date(new Date().setHours(23, 59, 59, 999)))
      console.log(picker[0].toString())
      console.log(picker[1].toString())
      this.searchForm.creationTime = picker[0].toString() + "," + picker[1].toString();
      /* this.params.startTime = picker[0].toString()
       this.params.endTime = picker[1].toString()*/
      /* 法二 */
      // let startDate = this.params.date.toString()
      // this.params.startTime = startDate.substring(0, 10)
      // this.params.endTime = startDate.substring(11)
    },
    rowClick(row, column, event) {
      this.editHandle(row.id)
      console.log(row);///获取该行的所有数据
      console.log(column);
      console.log(event);
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      console.log("勾选")
      console.log(val)
      this.multipleSelection = val;

      this.delBtlStatu = val.length == 0
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val
      this.getUserList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.current = val
      this.getUserList()
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false
      this.editForm = {}
    },
    handleClose() {
      this.resetForm('editForm')
    },

    getUserList() {

      /*  this.$nextTick(() => {
          // 在此处执行你要执行的函数
          console.log(" 1 =" +this.searchForm.creationTime[0])
        });
        console.log(" 2 =" +this.searchForm.creationTime)
        this.searchForm.creationTime=this.searchForm.creationTime[0];
        console.log(" 2 =" +this.searchForm.creationTime)*/
      if (this.value2 == null || this.value2 == '') {
        console.log("112" + this.value2)
        this.searchForm.creationTime = '';
      }
      const { key, mathKey } = getAesKeyByApp();

      var ids = { usEmail: this.searchForm.usEmail,
        cardNo: this.searchForm.cardNo,
        cardId: this.searchForm.cardId,
        type: this.searchForm.type,
        creationTime: this.searchForm.creationTime,
        countries: this.searchForm.countries,

        current: this.current,
        size: this.size}

      this.$axios.get("/card/cxpay/listS", {
        params: {
          key: rsaEncode(mathKey, publicKey),
          data: aesEncode(JSON.stringify(ids), key) + "",
          current: this.current,
          size: this.size
        }
      }).then(res => {
       /* this.tableData = res.data.data.records
        this.size = res.data.data.size
        this.current = res.data.data.current
        this.total = res.data.data.total*/
        const  jsonData = JSON.parse(res.data.data.countId)
        //console.log(JSON.parse(aesDecode(jsonData.data, key)));
        const  aesData = JSON.parse(aesDecode(jsonData.data, key))
        //console.log(aesData)


        //console.log("records  =      ==="+res.data.data.records)
        this.tableData = aesData;
        this.size = res.data.data.size
        this.current = res.data.data.current
        this.total = res.data.data.total

        //this.value2=[new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)];
      })
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //alert((this.editForm.id?'update' : 'save'))
          const { key, mathKey } = getAesKeyByApp();

          this.editForm.creationTime=null;
          this.editForm.modifyTime=null;
          this.editForm.mcreationTime=null;

          console.log("1111"+rsaEncode(mathKey, publicKey))
          //console.log("2222"+aesEncode(JSON.stringify(this.editForm), key))
          //this.editForm.theSurname=rsaEncode(mathKey, publicKey);
          //this.editForm.theName=aesEncode(JSON.stringify(this.editForm), key) +"";

          console.log(" ==  = =2 =2=2 2"+JSON.stringify(this.editForm))
          this.encryption.key=rsaEncode(mathKey, publicKey);
          this.encryption.data=aesEncode(JSON.stringify(this.editForm), key) +"";

          this.$axios.post('/card/cxpay/' + (this.editForm.id ? 'update' : 'save'),  this.encryption)
              .then(res => {
                //console.log(" 66 ++  === "+JSON.stringify(res.data))
                const  jsonData = JSON.parse(res.data.data)
                //console.log(JSON.parse(aesDecode(jsonData.data, key)));
                const  aesData = JSON.parse(aesDecode(jsonData.data, key))
               // console.log("77564676=="+JSON.stringify(aesData))
                //console.log("111111=="+aesData.code)
                //console.log("222222=="+aesData.msg)
                if(aesData.code === 200){
                  this.$message({
                    showClose: true,
                    message: '恭喜你，操作成功',
                    type: 'success',
                    onClose: () => {
                      this.getUserList()
                    }
                  });
                  this.dialogVisible = false
                }else{
                  Element.Message.error(aesData.msg, {duration: 25000})
                }

              })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    editHandle(id) {
      const { key, mathKey } = getAesKeyByApp();

      const ids = { id: id}


      this.$axios.get("/card/cxpay/TWEFGSasdasdwqeEkdfmngonqwghei7hqWTQW", {
        params: {
          key: rsaEncode(mathKey, publicKey),
          data: aesEncode(JSON.stringify(ids), key) + ""
        }
      }).then(res => {

        const  jsonData = JSON.parse(res.data.data)

        const  aesData = JSON.parse(aesDecode(jsonData.data, key))
        //console.log(JSON.stringify(aesData.data))
        this.editForm = aesData.data;

        this.dialogVisible = true
        //this.value2=[new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)];
      })

    },
    editHandleT(id) {
      this.$axios.get('http://visa.fomepay.com/APay/page/share/showcard.html?cardId=' + id).then(res => {
        this.editForm = res.data.data

        this.dialogVisible = true
      })
    },
    delHandle(id) {

      var ids = []

      if (id) {
        ids.push(id)
      } else {
        this.multipleSelection.forEach(row => {
          ids.push(row.id)
        })
      }
      console.log(ids)
      //alert(ids);


      this.$axios.post("/card/cxpay/delete", ids).then(res => {
        this.$message({
          showClose: true,
          message: '恭喜你，操作成功',
          type: 'success',
          onClose: () => {
            this.getUserList()
          }
        });
      })
    },
    sa(id) {
      var ids = []

      if (id) {
        ids.push(id)
      } else {
        this.multipleSelection.forEach(row => {
          ids.push(row.id)
        })
      }
      console.log(ids)
      //alert(ids);
      this.$axios.post("/card/cxpay/delete", ids).then(res => {
        this.$message({
          showClose: true,
          message: '恭喜你，操作成功',
          type: 'success',
          onClose: () => {
            this.getUserList()
          }
        });
      })
    },

    roleHandle(id) {
      this.roleDialogFormVisible = true

      this.$axios.get('/sys/userWQKKDUXHAL/info/' + id).then(res => {
        this.roleForm = res.data.data

        let roleIds = []
        res.data.data.sysRoles.forEach(row => {
          roleIds.push(row.id)
        })

        this.$refs.roleTree.setCheckedKeys(roleIds)
      })
    },
    submitRoleHandle(formName) {
      var roleIds = this.$refs.roleTree.getCheckedKeys()

      console.log(roleIds)

      this.$axios.post('/sys/userWQKKDUXHAL/role/' + this.roleForm.id, roleIds).then(res => {
        this.$message({
          showClose: true,
          message: '恭喜你，操作成功',
          type: 'success',
          onClose: () => {
            this.getUserList()
          }
        });

        this.roleDialogFormVisible = false
      })
    },
    repassHandle(id, username) {

      this.$confirm('将重置用户【' + username + '】的密码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post("/sys/userWQKKDUXHAL/repass", id).then(res => {
          this.$message({
            showClose: true,
            message: '恭喜你，操作成功',
            type: 'success',
            onClose: () => {
            }
          });
        })
      })
    }
  }
}
</script>

<style scoped>

.el-pagination {
  float: right;
  margin-top: 22px;
}

</style>