import CryptoJS from "crypto-js";
import JSEncrypt from "jsencrypt";

// const publicKey = ""

/**
 * aes加密
 * @param { String } content 加密内容
 * @param { String } key 密钥
 * @returns String
 */
export function aesEncode(content, key) {
    const _key = CryptoJS.enc.Utf8.parse(key);
    return CryptoJS.AES.encrypt(content, _key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
    });
}
/**
 *  aes解密
 * @param { String } decryptString aesEncode加密后的字符串
 * @param { String } key 密钥
 * @returns String
 */
export function aesDecode(decryptString, key) {
    // 需要解密的数据  aes密钥
    const _key = CryptoJS.enc.Utf8.parse(key);
    const decrypt = CryptoJS.AES.decrypt(decryptString, _key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
    });
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}
/**
 * rsa加密
 * @param { any } dat 需要加密的数据
 * @param { String} publicKey  公钥
 * @returns String
 */
export function rsaEncode(dat, publicKey) {
    const data = Object.prototype.toString.call(dat) === "[object String]" ? dat : JSON.stringify(dat);
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey);
    return encrypt.encrypt(data);
}
/**
 * rsa解密
 * @param { String} data  rsaEncode加密后的字符串
 * @param { String } privateKey rsa私钥
 * @returns String
 */
export function rsaDecode(data, privateKey) {
    const decrypt = new JSEncrypt();
    decrypt.setPrivateKey(privateKey);
    return decrypt.decrypt(data);
}
/**
 *
 * @param {Number} min 随机数最小值
 * @param {Number} max 随机数最大值
 * @returns Number
 */
export function getRandomIntInclusive(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
/**
 *
 * @param { Number|String } n 随机数长度
 * @param { Number } min 随机数范围最小值
 * @param { Number } max 随机数范围最大值
 * @returns String
 */
export function getAesKey(n = 8, min = 0, max) {
    const chars = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
    ];
    let res = "";
    for (let i = 0; i < n; i++) {
        const id = getRandomIntInclusive(min, max ?? chars.length - 1);
        res += chars[id];
    }
    return res;
}
/**
 * key 16位解密key
 * mathKey 32位传给后端的key
 * @returns Object
 */
export function getAesKeyByApp() {
    const preNum = getAesKey(4, 1, 9);
    const len = parseInt("1" + getAesKey(1, 1, 9));
    const timestamp = (new Date().getTime() + "").substr(0, 10);
    const subNum = (parseInt(preNum) + parseInt(len)) * 3;
    const mathKey = preNum + len + getAesKey(len, 1, 9) + timestamp + subNum;
    const key = getAesKey(16);
    return {
        key,
        mathKey: key + mathKey,
    };
}
