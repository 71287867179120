<template>
  <div>
    <div style="cursor:pointer">
      <i
          @click="clickTo"
          class="el-icon-full-screen"
          style="font-size:30px;position:absolute;right:20px;font-weight:700"
      ></i>
      <embed :src="src" width="100%" height="100%" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      src: "/aidS"
    };
  },
  created() {},
  methods: {
    clickTo() {
      window.open("/aidS", "_blank"); //跳转新页面
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
