<template>

    <el-container :span="24">

      <el-container>
        <el-header style="height: 75px;">Header</el-header>
        <el-main>Main</el-main>
        <el-footer style="height: 75px;">Footer</el-footer>
      </el-container>


    </el-container>



</template>

<script>
export default {
  name: "Wealth",
  data: () => ({
    show2: true
  })
}
</script>

<style scoped>
.el-row {
  margin-bottom: 20px;
&:last-child {
   margin-bottom: 0;
 }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.el-header, .el-footer {
  background-color: rgba(2, 20, 29, 0.85);
  color: #ffffff;
  text-align: center;
  line-height: 75px;
  font-size:23px;
}

.el-aside {
  background-color: #D3DCE6;
  color: #333;
  text-align: center;
  line-height: 200px;
  size: A5;
}

.el-main {
  background-color: rgba(235, 239, 239, 0.84);
  color: #333;
  text-align: center;

}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.transition-box {
  margin-bottom: 10px;
  width: 200px;
  height: 100px;
  border-radius: 4px;
  background-color: #409EFF;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
  margin-right: 20px;
}
</style>